var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',{key:_vm.date.getTime() + _vm.loaded,staticClass:"flix-container-fluid"},[_c('div',{staticClass:"flix-form-group"},[_c('calLegend',{attrs:{"callback":(exclude) => {
          _vm.getExclude(exclude)
        }}})],1),_c('div',{key:_vm.calID,staticClass:"flix-row"},[_c('calHeader',{staticClass:"flix-form-group",attrs:{"date":_vm.date,"callback":(date) => {
          _vm.setDate(date)
        }}})],1),_c('div',{staticClass:"flix-row flix-form-group flix-text-right"},[(_vm.$getUserVariables().state === 'user')?_c('saveBtn',{attrs:{"bookingCal":"","onClick":() => {
          _vm.settings()
        }},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$tc('message.settings', 2))+" ")]},proxy:true}],null,false,2004723105)}):_vm._e()],1),_c('div',{staticClass:"flix-row"},[_c('div',{staticClass:"flix-col-md-12 flix-form-group flix-text-center",style:('background-color: ' + _vm.getBgColor() + '; padding: 10px 0;')},[_c('b-flix-h1',{attrs:{"variant":"white"}},[_c('b-flix-icon',{attrs:{"icon":"calendar"}}),_vm._v(" "+_vm._s(_vm.$route.params.date.split('-')[2])+"."+_vm._s(_vm.$route.params.date.split('-')[1])+"."+_vm._s(_vm.$route.params.date.split('-')[0])+" ")],1)],1)]),(!_vm.calID)?_c('crmTable',{attrs:{"id":'all',"filter":_vm.date,"callback":() => {}}}):_vm._e(),_c('transition',{attrs:{"name":"flixFadeIn"}},[(_vm.calID)?_c('div',{key:_vm.excludeKey,staticClass:"flix-overflow"},[_c('div',{staticClass:"flix-form-group flix-text-right"},[_c('a',{staticClass:"flix-html-small flix-html-a",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (() => {
              _vm.getTableLink()
            }).apply(null, arguments)}}},[_c('flixIcon',{attrs:{"id":"table"}}),_vm._v(" "+_vm._s(_vm.$t('message.goTo', { name: _vm.$t('message.table') })))],1)]),_vm._l((_vm.$store.getters.assistents),function(assistent,index){return _c('div',{key:index,class:{ calWidth: !_vm.calID }},[(!_vm.calID || _vm.calID == assistent.ID)?_c('div',[_c('calRows',{key:_vm.$store.getters.bookingCalendarHash,attrs:{"date":_vm.date,"color":_vm.$store.getters.assistentsByID[_vm.calID].color ||
              _vm.$store.getters.colors[index],"assistent":assistent,"callback":() => {
                _vm.getTableLink()
              }}})],1):_vm._e()])})],2):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }