<template>
  <div
    class="flix-container-fluid"
    :key="date.getTime() + loaded"
    v-if="loaded"
  >
    <div class="flix-form-group">
      <calLegend
        :callback="
          (exclude) => {
            getExclude(exclude)
          }
        "
      />
    </div>
    <div class="flix-row" :key="calID">
      <calHeader
        :date="date"
        class="flix-form-group"
        :callback="
          (date) => {
            setDate(date)
          }
        "
      />
    </div>
    <div class="flix-row flix-form-group flix-text-right">
      <saveBtn
        v-if="$getUserVariables().state === 'user'"
        bookingCal
        :onClick="
          () => {
            settings()
          }
        "
      >
        <template v-slot:text>
          {{ $tc('message.settings', 2) }}
        </template>
      </saveBtn>
    </div>
    <div class="flix-row">
      <div
        class="flix-col-md-12 flix-form-group flix-text-center"
        :style="'background-color: ' + getBgColor() + '; padding: 10px 0;'"
      >
        <b-flix-h1 variant="white">
          <b-flix-icon icon="calendar"></b-flix-icon>
          {{ $route.params.date.split('-')[2] }}.{{
            $route.params.date.split('-')[1]
          }}.{{ $route.params.date.split('-')[0] }}
        </b-flix-h1>
      </div>
    </div>
    <crmTable v-if="!calID" :id="'all'" :filter="date" :callback="() => {}" />
    <transition name="flixFadeIn">
      <div :key="excludeKey" class="flix-overflow" v-if="calID">
        <div class="flix-form-group flix-text-right">
          <a
            class="flix-html-small flix-html-a"
            href="#"
            @click.prevent="
              () => {
                getTableLink()
              }
            "
            ><flixIcon id="table" />
            {{ $t('message.goTo', { name: $t('message.table') }) }}</a
          >
        </div>
        <div
          :class="{ calWidth: !calID }"
          v-for="(assistent, index) in $store.getters.assistents"
          :key="index"
        >
          <div v-if="!calID || calID == assistent.ID">
            <calRows
              :date="date"
              :key="$store.getters.bookingCalendarHash"
              :color="
                $store.getters.assistentsByID[calID].color ||
                $store.getters.colors[index]
              "
              :assistent="assistent"
              :callback="
                () => {
                  getTableLink()
                }
              "
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: { callback: Function },
  components: {
    calHeader: function () {
      return import('@/components/bookingCalendar/header')
    },
    calLegend: function () {
      return import('@/components/bookingCalendar/legend')
    },
    calRows: function () {
      return import('@/components/bookingCalendar/rows')
    },
    crmTable() {
      return import('@/components/crm/table')
    }
  },
  data() {
    var id = false
    if (typeof this.$route.params.id !== 'undefined') {
      id = this.$route.params.id
    }

    return {
      date: this.$store.getters.calendarDate,
      exclude: {},
      excludeKey: new Date().getTime(),
      calID: id,
      loaded: false
    }
  },
  watch: {
    '$route.params.id'(val) {
      this.calID = val
      this.excludeKey = new Date().getTime()
    }
  },
  mounted() {
    this.getLoadDates()
  },
  methods: {
    getTableLink() {
      var name = 'bookingCalendarDate'
      if (this.$getUserVariables().state === 'share') {
        name = 'sharesBookingCalendarDate'
      }
      this.$router.push({
        name: name,
        params: Object.assign(this.$route.params, { id: '' })
      })
    },
    getLoadDates() {
      this.loaded = false
      this.$store.dispatch(
        'getAssistents',
        function () {
          this.$store.dispatch(
            'getBookings',
            function () {
              this.loaded = true
            }.bind(this)
          )
        }.bind(this)
      )
    },
    settings() {
      this.$router.push({
        name: 'dashboardSettings'
      })
    },
    addCustomer() {
      var link = 'dashboardAddCustomer'
      var id = 'all'
      if (this.$getUserVariables().state === 'share') {
        link = 'sharesAddCustomer'
      }
      if (this.$route.params.id !== 'all') {
        id = this.$route.params.id
      }
      this.$router.push({
        name: link,
        params: {
          id: id
        }
      })
    },
    setCalID(id) {
      this.calID = id

      if (id === false) {
        this.$router.push({ name: 'bookingCalendar', params: { id: false } })
      }
    },
    setDate(date) {
      this.date = date

      this.$store.commit('setCalendarDate', date)

      this.$router.push({
        name: this.$route.name,
        params: {
          date:
            date.getFullYear() +
            '-' +
            this.$getNullsBefore(date.getMonth() + 1) +
            '-' +
            this.$getNullsBefore(date.getDate())
        }
      })
    },
    getExclude(data) {
      this.exclude = data
      this.excludeKey = new Date().getTime()
    },
    getBgColor() {
      if (
        typeof this.$store.state.assistents.assistentActive === 'object' &&
        typeof this.$store.state.assistents.assistentActive.color === 'string'
      ) {
        return this.$store.state.assistents.assistentActive.color
      }
      return '#42bd84'
    }
  }
}
</script>

<style lang="sass" scoped>

.flix-overflow
  overflow-x: auto
  overflow-y: hidden
  white-space: nowrap
  width: 100%
  padding: 0
  box-sizing: border-box
  display: inline-block

.calWidth
  width: 16.6%
  display: inline-block

@media (max-width: 500px)
  .calWidth
    width: 50%
</style>
